/**
 * Queue
 */


// strict mode
"use strict";
/* global moment */


jQuery(document).ready(

    function () {

        var refreshDelay = 10000,
            refreshFastDelay = 1000,
            qTO = null,
            pauseRefresh = false,
            qdoneEl = null,
            qdoneTO = null,
            qdoneTODelay = 300,
            updateTimeEl = jQuery('#server-time'),

            // @see ::doneFilterButtonAction()
            // @see ::doneQueueLoad()
            doneFilterSelection = null;

        // If page has a queue, make magic happen
        if (jQuery('#queue').length) {
            setupQueueContent();
        }


        /**
         * With updated content update DOM, wait a bit and do it again.
         * pauseRefresh global if set prevents DOM update.
         * @param string HTML
         */
        function updateQueueContent(html) {
            if (qTO) {
                clearTimeout(qTO);
                qTO = null;
            }
            // Server time
            if (updateTimeEl.length) {
                updateTimeEl.text(
                    moment().format('MMM Do h:mm:ssa')
                );
            }
            // Are we paused
            if (pauseRefresh) {
                qTO = setTimeout(
                    function () {
                        updateQueueContent('');
                    },
                    500
                );
                return;
            }
            // Is the html we expect
            if (html.indexOf('id="queue"') >= 0) {
                // Update Queue Contents
                jQuery(html).children().each(
                    function(_idx, h) {
                        var jhtml = jQuery(h),
                            id = jhtml.attr('id');
                        if (id !== 'queueDone') {
                            jQuery('#' + id).html(jhtml.children());
                        }
                    }
                )
                // jQuery('#queue').html(jQuery(html).children());
            }

            // After delay, get new content
            qTO = setTimeout(getUpdateQueueContent, jQuery('#queueDoingNone').length ? refreshDelay : refreshFastDelay);
        }


        /**
         * Update performed_filter_command options
         * @param array command_list
         */
        function updatePerformedFilterCommandsOptions(command_list) {
            var select_filter = jQuery('select#performed_filter_command'),
                select_filter_options = select_filter.find('option:not(:first)'),
                name, c;
            for (c = 0; c < command_list.length; c++) {
                name = command_list[c];
                // If current list is shorter, add new option tag
                if ( select_filter_options.length <= c ) {
                    jQuery('<option />').attr('value', name).text(name).appendTo(select_filter);
                }
                // If  value is different, reset it
                else if ( select_filter_options.eq(c).attr('value') != name ) {
                    select_filter_options.eq(c).attr('value', name).text(name);
                }
            }
            // If current list is longer than command list, trim it
            while (select_filter_options.length > command_list.length) {
                select_filter_options.eq(select_filter_options.length - 1).remove();
            }
        }


        /**
         * Performed search or reset button clicked. Clear any previous timeout and start new.
         */
        function doneFilterButtonAction(e) {
            var target = jQuery(e.target),
                action = target.attr('id').match(/reset/i) ? 'reset' : 'search';

            // Handle everything here
            e.stopPropagation();
            e.preventDefault();

            // Reset refresh timeout if set
            if ( qdoneTO ) {
                clearTimeout(qdoneTO);
                qdoneTO = null;
            }

            // Reset Action
            if ( doneFilterSelection === null || action === 'reset' ) {
                doneFilterSelection = '';
                // Reset form select controls to first item
                qdoneEl.find('form select').each(
                    function(_index, select_ele) {
                        jQuery(select_ele).get(0).selectedIndex = 0;
                    }
                );
                // Reset text, date, number, etc. elements (MODIFY IF ADD RADIO OR CHECKBOXES)
                qdoneEl.find('form input').val('');
            }
            // Search Action
            else {
                doneFilterSelection = jQuery('#performed_filter form').serialize();
            }
            // Show content is being refreshed
            doneQueueLoading();
            // Perform refresh, reset offset
            doneQueueLoad({offset: 0});
        }


        /**
         * Refresh done queue
         */
        function doneQueueRefresh() {
            doneQueueLoad({});
        }


        /**
         * Show doneQueue is being loaded (search, reset, pagination)
         */
        function doneQueueLoading() {
            qdoneEl.find('.table-rows').fadeTo(0.5, 0.4);
        }


        /**
         * Remove doneQueue loading
         */
        function doneQueueLoadingDone() {
            qdoneEl.find('.table-rows').fadeTo(0.5, 1);

        }


        /**
         * Paging click
         * @param object Event
         */
        function doneQueuePagingClick(e) {
            var target = jQuery(e.target),
                pg = parseInt(target.attr('data-pg')),
                pp = parseInt(target.closest('.qPagination').attr('data-perpage'));

            // Only if this isn't disabled
            if (target.attr('disabled') !== 'disabled' && !isNaN(pg) && !isNaN(pp))
                // Show content is being refreshed
                doneQueueLoading();
                // Perform refresh, update offset to new page
                doneQueueLoad(
                    {offset: pg * pp}
                );
        }


        /**
         * Load Done Queue items
         * @param object 'limit', 'offset', 'filter'
         */
        function doneQueueLoad(getOptions) {
            var qPagination = qdoneEl.find('.qPagination'),
                offset = parseInt(qPagination.attr('data-offset')),
                data = Object.assign(
                    {
                        get     : 'queuedone',
                        filter  : doneFilterSelection,
                        limit   : 50,
                        offset  : isNaN(offset) ? 0 : offset
                    },
                    getOptions
                );

            if (qdoneTO) {
                clearTimeout(qdoneTO);
                qdoneTO = null;
            }
            // Get updated Done HTML (tbody TR items)
            // if (filterInput.data('lastval') != data.filter) {
            //     filterInput.data('lastval', data.filter);
            //     filterInput.addClass('updating');
            // }


            jQuery.post(
                window.location.pathname,
                data,
                function (response) {
                    var qdone = JSON.parse(response);
                    // Replace table rows
                    qdoneEl.find('.table-rows').html(qdone.html);
                    // If was showing we're loading, show as done
                    doneQueueLoadingDone();
                    // Update paging
                    doneQueuePaging(qdone.total, data.offset, data.limit);
                    // Update filter commands menu options if needed
                    updatePerformedFilterCommandsOptions(qdone.filter_commands);
                    // Be sure the filter is displaying (only hidden initially)
                    jQuery('#performed_filter').removeClass('d-none');
                    // Repeat after delay
                    qdoneTO = setTimeout(doneQueueRefresh, refreshDelay);
                }
            )
            .fail( function(response) {
                console.log('Error in getting queuedone: ' . $repoonse.responseText);
            });
        }


        /**
         * Build/update paging controls for done items
         * @param integer Total records
         * @param integer Current offset
         * @param integer Per page
         */
        function doneQueuePaging(total, offset, perpage) {
            var qPagination = qdoneEl.find('.qPagination'),
                pages = Math.ceil(total / perpage),
                curpg = Math.floor(offset / perpage),
                i, disable = [], enable = [];

            if (qPagination.length === 0) {
                qPagination = jQuery('<div class="qPagination" />')
                    .append(
                        jQuery('<ul />')
                            .append('<li class="pg-first"><a href="#first" tabindex="-1">&lt;&lt;</a>')
                            .append('<li class="pg-prev"><a href="#prev" tabindex="-1">&lt;</a>')
                            .append('<li><div /></li>')
                            .append('<li class="pg-next"><a href="#next" tabindex="-1">&gt;</a>')
                            .append('<li class="pg-last"><a href="#last" tabindex="-1">&gt;&gt;</a>')
                    )
                    .appendTo(qdoneEl.find('.card-body'));
                // Insert copy above table
                qPagination.clone().insertBefore(qdoneEl.find('.card-body > table'));
                qPagination = qdoneEl.find('.qPagination');
                // Listen for clicks
                qdoneEl.on('click', '.qPagination a', doneQueuePagingClick);
            }
            // Update previous, next, and last
            qPagination.find('.pg-first a').attr('data-pg', 0);
            qPagination.find('.pg-prev a').attr('data-pg', i = curpg);
            i <= 0 ? disable.push('prev') : enable.push('prev');
            i <= 0 ? disable.push('first') : enable.push('first');
            qPagination.find('.pg-next a').attr('data-pg', i = (curpg + 1));
            i >= pages ? disable.push('next') : enable.push('next');
            i >= pages ? disable.push('last') : enable.push('last');
            qPagination.find('.pg-last a').attr('data-pg', pages - 1);
            // Hide/show applicable controls
            disable.forEach(
                function(v) {
                    var el = qPagination.find('.pg-' + v + ' a');
                    el.css('visibility', 'hidden');
                    el.attr('disabled', 'disabled');
                }
            );
            enable.forEach(
                function(v) {
                    var el = qPagination.find('.pg-' + v + ' a');
                    el.css('visibility', 'visible');
                    el.removeAttr('disabled');
                }
            );
            // Update textual position
            qPagination.attr('data-perpage', perpage);
            qPagination.attr('data-total', total);
            qPagination.attr('data-offset', offset);
            qPagination.find('div').html(
                'Showing <strong>' + ((curpg * perpage) + 1).toString() + '</strong>'
                + ' to <strong>' + Math.min(total, ((curpg + 1) * perpage)).toString() + '</strong>'
                + ' of <strong>' + total + '</strong>'
            );
        }


        /**
         * Request new queue content from server
         */
        function getUpdateQueueContent() {
            jQuery.post(
                window.location.pathname,
                {
                    get: 'queue'
                },
                updateQueueContent
            );
        }


        /**
         * Setup
         */
        function setupQueueContent() {
            /* Look for layout toggle */
            jQuery('#queue-layout-toggle')
                .on(
                    'click',
                    function(e) {
                        jQuery('#queue-layout')
                            .toggleClass('queue-layout-short');
                    }
                )
                .trigger('click');

            // Initiate refresh
            updateQueueContent('');

            // Done Queue container
            qdoneEl = jQuery('#queueDone');
            // Done Queue Filter buttons
            jQuery('#performed_filter_btn_reset, #performed_filter_btn_search')
                .on('click', doneFilterButtonAction)
                .first()
                    .trigger('click');

            // Listen for Quick Edit buttons
            // jQuery(document)
            //     .on(
            //         'click',
            //         '.quick-edit',
            //         quickEditClick
            //     );

            jQuery(document)
                // Queue delete buttons
                .on(
                    'click',
                    'button[name="delQueueItem"]',
                    function (e) {
                        var btn = jQuery(this),
                            editItem = btn.closest('.queueItem'),
                            uid = editItem.attr('data-uid'),
                            data = {
                                del: 'queue',
                                delVal: uid
                            };

                        e.preventDefault();
                        if (confirm('Really delete this item from the Queue?')) {
                            editItem.slideUp('fast');
                            jQuery.post(
                                window.location.pathname,
                                data,
                                updateQueueContent
                            );
                        }
                    }
                )

                // Setup listen for click/edit todo queue items, or reset doing queue items
                .on(
                    'click',
                    'button[name="editQueueItem"], button[name="resetQueueItem"]',
                    function(e) {
                        var btn = jQuery(this),
                            isEdit = btn.filter('[name="editQueueItem"]').length > 0,
                            editItem = btn.closest('.queueItem'),
                            uid = editItem.attr('data-uid');

                        if (!isEdit && !confirm('Reset this item? This ONLY resets the queue item, not the currently running process.')) return;

                        jQuery.post(
                            window.location.pathname + '?edit-queue-item',
                            { editItem: uid, edit: isEdit ? 'queue' : 'resetQueueItem' },
                            function(response) {
                                response = JSON.parse(response);
                                if (response.error) {
                                    showEditModal(
                                        '<p>' + (typeof response.error == 'string' ? response.error : response.error.join('</p><p>')) + '</p>',
                                        'Error' + editItem.find('.cmd-display').html(),
                                        '<button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>'
                                    );
                                }
                                // If edit button, response will be HTML to edit - place in modal and display
                                else if (isEdit) {
                                    showEditModal(
                                        jQuery('<form />').html(response.html),
                                        'Edit Queue Item: ' + editItem.find('.cmd-display').html(),
                                        '<button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>' +
                                        '<button type="button" class="btn btn-primary edit-item-save-btn" data-edituid="' + uid + '">Save Changes</button>'
                                    );
                                }
                                // Otherwise refresh queue data
                                else {
                                    getUpdateQueueContent();
                                }
                            }
                        )
                    }
                )

                // Handle saving queue item edits
                .on(
                    'click',
                    '.edit-item-save-btn',
                    function(e) {
                        var button = jQuery(e.target),
                            uid = button.attr('data-edituid'),
                            modal = button.closest('.modal'),
                            data = modal
                                // Add item's options
                                .find('input, select, textarea')
                                // Prepare to send
                                .serializeArray();

                        data.push({name: 'edit', value: 'queue'});
                        data.push({name: 'editItem', value: uid});
                        data.push({name: 'save', value: uid});
                        button.prop("disabled", true);
                        jQuery.post(
                            window.location.pathname + '?edit-queue-item-save',
                            data,
                            function (response) {


                                response = JSON.parse(response);
                                button.prop("disabled", false);
                                var ews = [{property:'error', alert:'danger'}, {property:'warning', alert:'warning'}],
                                    i, ew, ap2, idx, fld, msg;

                                // Check and display any error and warnings
                                for (i in ews) {
                                    ew = ews[i],
                                    // Remove previous messages
                                    modal.find('.data-field-' + ew.property).remove();
                                    // Is there a warning or error
                                    if (response[ew.property]) {
                                        // Normalize value as iterable object
                                        if (typeof response[ew.property] == 'string') {
                                            response[ew.property] = [response[ew.property]];
                                        }
                                        for (idx in response[ew.property]) {
                                            fld = modal.find('.argument-field-' + idx);
                                            ap2 = fld.length ? fld : modal.find('.modal-body');
                                            ap2.append(
                                                jQuery('<div class="alert" />')
                                                    .addClass('alert-' + ew.alert)
                                                    .addClass('data-field-' + ew.property)
                                                    .append(
                                                        '<p>' + (typeof response[ew.property][idx] == 'string'
                                                            ? response[ew.property][idx]
                                                            : response[ew.property][idx].join('</p><p>')) + '</p>'
                                                    )
                                            );
                                        }
                                    }
                                }

                                // If we're good, close modal
                                if (!response.error && !response.warning) {
                                    // Hide the modal
                                    hideEditModal();
                                    // Update the queue contents
                                    getUpdateQueueContent();
                                }
                            }
                        );
                    }
                );


            var
                editModal = null;

            function showEditModal(html, title, buttons) {
                if (!editModal) {
                    editModal = jQuery(
                        '<div class="modal" tabindex="-1">' +
                            '<div class="modal-dialog modal-lg">' +
                                '<div class="modal-content">' +
                                    '<div class="modal-header">' +
                                        '<h5 class="modal-title"></h5>' +
                                        '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
                                            '<span aria-hidden="true">&times;</span>' +
                                        '</button>' +
                                    '</div>' +
                                    '<div class="modal-body"></div>' +
                                    '<div class="modal-footer"></div>' +
                                '</div>' +
                            '</div>' +
                        '</div>').appendTo(document.body);
                }
                editModal.find('.modal-body').empty().html(html);
                editModal.find('.modal-title').empty().html(title);
                editModal.find('.modal-footer').empty().html(buttons);
                editModal.modal('show');
            }

            function hideEditModal() {
                if (editModal) {
                    editModal.modal('hide');
                }
            }


            // Setup Add To Queue functionality
            var
                addQueue = jQuery('#addToQueue'),
                addQueueForm = addQueue.find('form'),
                addQueueEl = addQueueForm.find('[name="addCommand"'),
                // repeatEl = addQueueForm.find('[name^="repeat"]'),
                buttonsEl = addQueueForm.find('.show-buttons'),
                feedbackEl = addQueueForm.parent().find('.feedback');


            // Show additional fields based on add selection
            addQueueEl
                .on(
                    'change',
                    function (e) {
                        var select = jQuery(this),
                            selsid = select.find('option:selected').attr('data-show-id'),
                            show = '.show-id-' + selsid,
                            hideshow = addQueueForm.find('.hide-show');
                        hideshow.not(show).removeClass('active-options').slideUp('fast');
                        hideshow.filter(show).addClass('active-options').slideDown('fast');
                        if (hideshow.filter(show).length) {
                            hideshow.filter(show)[0].focus();
                        }
                    }
                )
                .trigger('change');

            addQueue.on('change keyup', checkButtons);
            /**
             * Call after a change to the addQueueElement, and changes and keyups to hide-show panels.
             * Show or hide the add and repeat elements
             */
            function checkButtons() {
                var show = true;
                // Only show if no argument text inputs with no value
                show &= addQueueForm.find('.active-options input')
                    .filter('[type!="checkbox"]')
                    .filter('[type!="radio"]')
                    .filter(function () {
                        return this.value.replace(/(^\s+|\s+$)/g, '').length > 0;
                    })
                    .length === 0;
                // TODO: Support check and radios, maybe validation beyond any content
                show &= addQueueForm.find('.active-options select')
                    .filter(function () {
                        return jQuery(this).val() != '';
                    })
                    .length === 0;
                if (show) buttonsEl.removeClass('active-add').slideUp('fast');
                else buttonsEl.addClass('active-add').slideDown('fast');
            }

            // Custom handle form
            addQueueForm
                .on(
                    'submit',
                    function (e) {
                        var errFld = null,
                            good = true,
                            data = addQueueForm
                                // Add item's options
                                .find('.active-options input, .active-options select, .active-options textarea')
                                // Add the item type
                                .add(addQueueEl)
                                // .add(repeatEl)
                                // Add repeat options and scheduled date (if any)
                                .add('.active-add [name^=repeat], .active-add [name^=schdate]')
                                // Prepare to send
                                .serializeArray();

                        e.preventDefault();
                        try {
                            // First make sure showButtons is happy - would mean all fields satisfied
                            checkButtons();
                            good = buttonsEl.hasClass('active-add');

                            // Command to add fields in data
                            data.push({
                                name: 'add',
                                value: 'queue'
                            });
                        } catch (err) {
                            alert(err);
                            if (errFld) {
                                errFld.focus();
                            }
                            good = false;
                        }

                        if (good) {
                            addQueueForm.slideUp('fast');
                            if (feedbackEl.length === 0) {
                                feedbackEl = jQuery('<div id="feedback"><div class="spinner-border m-2" role="status"></div></div>').slideUp(1);
                                addQueueForm.parent().append(feedbackEl);
                            }
                            feedbackEl.slideDown('fast');

                            jQuery.post(
                                window.location.pathname,
                                data,
                                function (response) {
                                    addQueueEl.val('').trigger('change');
                                    feedbackEl.slideUp('fast');
                                    addQueueForm.slideDown('fast');

                                    // Show updated queue
                                    updateQueueContent(response);
                                }
                            );
                        }
                    }
                );

            // Show the add queue form
            jQuery('#addToQueueBtn').removeClass('d-none');

        }

    }

);