/**
 * Properties
 */


// strict mode
"use strict";



jQuery(document).ready(

    function () {

        var // sourceDataName is source table hs properties are being mapped.
            sourceDataName = jQuery('table[data-source]').attr('data-source'),
            // forcedLinkFld is set when user focuses on a src_fld text input.
            focusedLinkFld = null;


        /**
         * With every data row:
         *  1) Create controls
         *  2) Show/hide the appropriate controls
         */
        jQuery('tr[data-id]').each(
            function(_idx, tr) {
                // Create control for capturing data
                jQuery(tr).find('.mdd_capture_choice')
                    .each(create_mdd_capture_choice);

                // Create a text input for source field
                jQuery(tr).find('.src_fld_text')
                    .each(create_src_fld_text);

                // Create select choice for each valid Sync option.
                jQuery(tr).find('.mdd_sync_choice')
                    .each(create_mdd_sync_choice);

                // Adjust the controls to match the values
                updateRowSyncOptions(jQuery(tr));
            }
        );


        /**
         * Create MDD Capture Choice. Passed element and value describes control value and options.
         * @param integer Index of choice being build (not used - allow calling directly w/jQuery.each())
         * @param object HTML Element new control will be added
         *   Should be CSS Class 'mdd_sync_choice'.
         *   Include 'data-id' name of control.
         *   Include 'data-choices' is a comma-separated list of value options, each option is "<value>:<label>".
         *   Include 'data-value' is current value.
         * @return void Creates HTML within passed mdd_capture_choice
         */
        function create_mdd_capture_choice(_index, mdd_capture_choice) {
            var container = jQuery(mdd_capture_choice),
                select = jQuery('<select class="hsPropertiesEdit" />');

            select.attr('name', container.attr('data-id'))
            container.attr('data-choices').split(',').forEach(
                function(val) {
                    var p = val.split(':');
                    jQuery('<option />')
                        .attr('value', p[0])
                        .text(p[1])
                        .appendTo(select);
                }
            );
            select.val(container.attr('data-value'));
            container.empty().append(select);
        }


        /**
         * Create MDD Sync Choice. Passed element and value describes control value and messages.
         * @param integer Index of choice being build (not used - allow calling directly w/jQuery.each())
         * @param object HTML Element.
         *   Should be CSS Class 'mdd_sync_choice'.
         *   Include 'data-id' name of control.
         *   Include 'data-choice' value of control.
         *   Include 'data-label' descriptive label.
         *   Text contents is any reason this control is not applicable.
         * @return void Creates HTML within passed mdd_sync_choice
         */
        function create_src_fld_text(_index, src_fld_text) {
            var container = jQuery(src_fld_text),
                input = jQuery('<input type="text" class="src_fld hsPropertiesEdit" />');

            input.attr('name', container.attr('data-id'));
            input.attr('id', container.attr('data-id'));
            input.attr('placeholder', 'source or destination');
            input.val(container.text());
            input.attr('data-sync', container.attr('data-sync'));
            container.empty().append(input);
        }


        /**
         * Create MDD Sync Choice. Passed element and value describes control value and messages.
         * @param integer Index of choice being build (not used - allow calling directly w/jQuery.each())
         * @param object HTML Element.
         *   Should be CSS Class 'mdd_sync_choice'.
         *   Include 'data-id' name of control.
         *   Include 'data-choice' value of control.
         *   Include 'data-label' descriptive label.
         *   Text contents is any reason this control is not applicable. Ignores contents if same as data-choice or data-label.
         *   Includes 'data-flags' integer to use if any data-flag-choices provided.
         *   includes 'data-flag-choices' string '<int-val>:<flag label>;....' if any flags applicatible.
         * @return void Creates HTML within passed mdd_sync_choice
         */
        function create_mdd_sync_choice(_index, mdd_sync_choice) {
            var container = jQuery(mdd_sync_choice),
                // Name of field to show/edit
                name = container.attr('data-id'),
                // Value of edit field when checked
                choice = container.attr('data-choice'),
                // Description
                label = container.attr('data-label'),
                // Alternate content - why input not displayed if any
                value = container.text().replace(/(^\s*|\s*$)/g, ''),
                // ID of input radio
                id = name + choice,
                // Item is checked
                checked = value == choice || value == label,
                // Flags value (if any)
                flags = container.attr('data-flags'),
                // Flag Choice (if any)
                flag_choices = container.attr('data-flag-choices'),
                // ID for flags choices
                flags_id = id + '-flags',
                // Name for flags choices
                flags_name = name + '_flags[]';

            // Dump contents (captured in value about)
            container.empty();
            // Add a label (not technically needed, just good practice)
            jQuery('<label class="sr-only" />')
                .attr('for', id)
                .text(label)
                .appendTo(container);
            // Add actual radio option.
            jQuery('<input type="radio" class="hsPropertiesEdit">')
                .attr('name', name)
                .attr('id', id)
                .attr('title', label)
                .val(choice)
                .prop('checked', checked)
                .appendTo(container);
            // If checked, set class
            if ( checked ) {
                container.closest('.field-mapping').addClass('sync-choice-selected');
            }

            // If there are flag choices, append to container
            if ( flag_choices ) {
                append_mdd_sync_flags(flags, flag_choices, flags_id, flags_name, container);
                set_mdd_sync_flags_set(container, flags);
            }
            // Add more info content container. This OR radio shows depending on contain CSS Class.
            set_mdd_sync_choice_more_info(
                jQuery('<div class="more-info-container" />'),
                value == choice || value == label ? '' : value
            ).appendTo(container);
        }

        /**
         * Sync chioce has flag choices, append checkbox flag choices.
         * @param integer flags
         * @param string flag_choices
         * @param object container
         * @return void
         */
        function append_mdd_sync_flags(flags, flag_choices, flags_id, flags_name, container) {
            var flag_choices_array = flag_choices.split(';'),
                wrapper = jQuery('<div class="flags-choices" />'),
                flags_mask;

            // Build checkboxes of flag choices. Return mask value.
            flags_mask = flag_choices_array.reduce(
                function (previousValue, currentValue, currentIndex) {
                    var parts = currentValue.split(':', 2),
                        value = parseInt(parts[0]),
                        label = jQuery('<label class="flags-choice" />').text(parts[1]),
                        checkbox = jQuery('<input type="checkbox" class="hsPropertiesFlags" />').attr('id', flags_id + currentIndex).attr('name', flags_name).val(value);

                    // Insert checkbox first in label
                    checkbox.prependTo(label);
                    // Append wrapped in label to container
                    wrapper.append(label);
                    // Build mask value
                    return previousValue + value;
                },
                0
            );
            // We need this when saving a flag change
            wrapper.attr('data-flags-mask', flags_mask);
            // Add to container
            wrapper.appendTo(container);
        }


        /**
         * For passed table call, append class indicating a flag is set
         * @param {*} container
         * @param string|integer flags
         */
        function set_mdd_sync_flags_set(container, flags) {
            var field_mapping_cell = container.closest('td.field-mapping'),
                flags_set = 0,
                flags_value = parseInt(flags);

            if ( isNaN(flags_value) ) {
                flags_value = 0;
            }
            // Find flags applicable
            field_mapping_cell.find('input[type=checkbox].hsPropertiesFlags').each(
                function(_idx, input) {
                    var input_element = jQuery(input),
                        input_value = parseInt(input_element.val()),
                        checked = (input_value & flags_value) > 0;
                    // Set check option
                    input_element.prop('checked', checked);
                    // Accumulate total
                    if ( checked ) {
                        flags_set += input_value;
                    }
                }
            )

            // Set class if flags are set
            if ( flags_set > 0 ) {
                field_mapping_cell.addClass('flags-set');
            }
            else {
                field_mapping_cell.removeClass('flags-set');
            }
        }


        /**
         * Format contents of mdd_sync_choice div (more info container)
         * @param object Div jQuery object
         * @param string Text content
         * @return moreInfoContainer - chainable - sets text content in div
         */
        function set_mdd_sync_choice_more_info(moreInfoContainer, text) {
            var str = text ? text : '',
                sep = str.indexOf(':');

            moreInfoContainer.text(sep < 0 ? str : str.substr(0, sep));
            if (sep >= 0) {
                moreInfoContainer
                    .addClass('more-info')
                    .append(
                        jQuery('<div />').text(str.substr(sep + 1))
                    );
            }
            else {
                moreInfoContainer.removeClass('more-info');
            }
            return moreInfoContainer;
        }


        /**
         * Pass row container (tr[data-id])
         * For each checkbox, if text contains value different than radio value, hide radio and show value.
         * If no src_fld value, hide all checkboxes
         * Be sure any hidden checks are unchecked.
         * Be sure if any radios are showing one is checked.
         * The srcFld is expected to have .data('sync_options') defined.
         * Those properties are choices values, non-null value is reason option not available (hide option, display why)
         * @param object tr element (jQuery wrapped)
         */
        function updateRowSyncOptions(rowContainer) {
            var srcFld = rowContainer.find('input[type="text"].hsPropertiesEdit'),
                syncOptions = srcFld.data('sync-options'),
                syncChoices = rowContainer.find('.mdd_sync_choice'),
                fieldMappingCells = rowContainer.children('.field-mapping'),
                fieldMappingSelected = null,
                choice;

            // If there is a value in the srcFld, show the radios
            if (srcFld.val()) {
                // If syncOptions exists update option html
                if (typeof syncOptions === 'object') {
                    for (choice in syncOptions) {
                        set_mdd_sync_choice_more_info(
                            rowContainer.find('[data-choice="' + choice + '"] div.more-info-container'),
                            syncOptions[choice]
                        );
                    }
                }
                // Assume no input should show
                syncChoices.filter('.show-input').removeClass('show-input');
                // All with empty div elements show show
                syncChoices.find('div.more-info-container:empty').each(
                    function(_idx, div) {
                        jQuery(div).closest('.mdd_sync_choice').addClass('show-input');
                    }
                );
                // Be sure no input in non show container is checked
                syncChoices.not('.show-input').find('input.hsPropertiesEdit:checked')
                    .prop('checked', false).trigger('change');
                // Be sure at least one input (if any) is checked
                if (syncChoices.filter('.show-input').find('input.hsPropertiesEdit:checked').length === 0) {
                    syncChoices.filter('.show-input').find('input.hsPropertiesEdit').first().prop('checked', true);
                }
                // Show choices
                rowContainer.removeClass('hide-sync-choices');
                // Last check that the mdd_capture is not off
                choice = rowContainer.find('.mdd_capture_choice .hsPropertiesEdit');
                if (choice.val() == '-') {
                    // Set capture to first non-off choice
                    choice.val(choice.find('option').not('[value="-"]').first().val());
                }
                // Set sync-choice-selected class on fieldMapping of selected cell
                fieldMappingSelected = fieldMappingCells.find('input.hsPropertiesEdit:checked').closest('.field-mapping');
                fieldMappingCells.not(fieldMappingSelected).removeClass('sync-choice-selected');
                fieldMappingSelected.addClass('sync-choice-selected');
            }
            // Hide the radios and uncheck any checked
            else {
                fieldMappingCells.removeClass('sync-choice-selected');
                rowContainer.addClass('hide-sync-choices');
                syncChoices.find('input').filter(':checked').prop('checked', false).trigger('change');
            }
        }


        /**
         * Click or focus on src_fld.
         *  1) Set current focused field
         *  2) Setup modal and display it
         * @param object Event
         * @return void
         */
        function on_src_fld_focus(e) {
            var modal, label;

            // Set item focused
            focusedLinkFld = jQuery(this);
            // Create or get modal structure
            modal = getModal();
            // Try to find something to display
            label = focusedLinkFld.closest('tr').find('td');

            modal.find('.modal-title')
                .empty()
                .html(
                    'Set Pivotal Field that matches this field' + (label.length ? ':<br> ' + label.first().html() : '')
                );
            // Display
            modal.modal('show');
            // Show selected
            modal.find('li.selected').removeClass('selected');
            modal.find('li[data-fld="' + focusedLinkFld.val() + '"]').addClass('selected');
        }



        // Listen for clicks and changes, and updates
        jQuery(document)
            // When clicking/focus on src_fld text input
            .on(
                'focus',
                'input.src_fld',
                on_src_fld_focus
            )

            // Listen for Flag changes
            .on(
                'change',
                'input[type=checkbox].hsPropertiesFlags',
                function (e) {
                    var input = jQuery(e.target),
                        flags_container = input.closest('.flags-choices'),
                        flags_mask = flags_container.attr('data-flags-mask'),
                        flags_value = flags_container.find('input:checked').toArray().reduce(
                            function(total, cbox) {
                                return total + parseInt( jQuery(cbox).val() );
                            },
                            0
                        ),
                        data = {
                            sourceDataName: sourceDataName,
                            hsProperty: input.closest('[data-id]').attr('data-id'),
                            flags: flags_value,
                            flags_mask: flags_mask
                        };

                    jQuery.post(
                        '?ajr=dataHsProperties&et=' + e.type,
                        data,
                        function (response) {
                            console.log(response);
                            var res = typeof response === 'object' ? response : JSON.parse(response),
                                // Userfld record
                                userfld = 'userfld' in res ? res.userfld : null,
                                // Sync choice
                                mdd_sync_choice_input = (userfld ? jQuery('#hsp-' + userfld.tbl + '-' + userfld.src_tbl + '-' + userfld.name + userfld.sync) : null),
                                // Wrapper for sync choice
                                mdd_sync_choice = mdd_sync_choice_input.closest('.mdd_sync_choice');

                            console.log(response);
                            console.log(mdd_sync_choice);
                            if ( mdd_sync_choice && mdd_sync_choice.length ) {
                                // Update srcFld data-flags with new value
                                mdd_sync_choice.attr('data-flags', userfld.flags);
                                // Update flag checkbox options
                                set_mdd_sync_flags_set(mdd_sync_choice, userfld.flags);
                            }
                        }
                    )
                }
            )

            // When a change occurs with a .hsPropertiesEdit input
            .on(
                'change',
                '.hsPropertiesEdit',
                function (e) {
                    var input = jQuery(e.target),
                        fld = input.closest('[data-fld]').attr('data-fld'),
                        data = {
                            sourceDataName: sourceDataName,
                            hsProperty: input.closest('[data-id]').attr('data-id')
                        };

                    // Input is a check/radio
                    if (input.is('input') && input[0].type.match(/(checkbox|radio)/i)) {
                        data[fld] = input.prop('checked') ? input.val() : '';
                    }
                    // Text or Select
                    else {
                        data[fld] = input.val();
                    }

                    jQuery.post(
                        '?ajr=dataHsProperties&et=' + e.type,
                        data,
                        function (response) {
                            var res = typeof response === 'object' ? response : JSON.parse(response),
                                // MDD property record
                                property = 'property' in res ? res.property : null,
                                // Source input.
                                srcFld = (property ? jQuery('#hsp-' + property.tbl + '-' + res.src_tbl + '-' + property.name) : null),
                                // Status of operation
                                update = 'update' in res ? res.update : {},
                                //
                                userFld = 'userfld' in res ? res.userfld : null;

                            // Was this an update
                            if (res.status == 'Updated') {
                                // Was the change the src_fld
                                if ( userFld ) {
                                    // Set the current sync value
                                    srcFld.attr('data-sync', userFld.sync);
                                    // Set the current sync options
                                    srcFld.data('sync-options', userFld.sync_options);
                                    // If src_fld changed, update sync options (show/hide)
                                    if ( 'src_fld' in update || 'sync' in update ) {
                                        updateRowSyncOptions(srcFld.closest('tr[data-id]'));
                                    }
                                }
                            }
                            // Was a src_fld removed
                            else if (res.status == 'Removed') {
                                // Set the current sync value
                                srcFld.removeAttr('data-sync');
                                // Update sync options hide all
                                updateRowSyncOptions(srcFld.closest('tr[data-id]'));
                            }
                        }
                    );
                }
            );



        /**
         * Create or get modal stucture
         * @return object Model container
         */
        function getModal() {
            var modal = jQuery('#pivotalFldModal');

            if (modal.length === 0) {
                modal = jQuery(
                    '<div class="modal fade" id="pivotalFldModal" tabindex="-1" role="dialog" aria-labelledby="pivotalFldModalLabel" aria-hidden="true"> \
                        <div class="modal-dialog modal-md" role="document"> \
                            <div class="modal-content"> \
                                <div class="modal-header"> \
                                    <h5 class="modal-title" id="pivotalFldModalLabel">Modal title</h5> \
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
                                        <span aria-hidden="true">&times;</span> \
                                    </button> \
                                </div> \
                                <div class="modal-body"> \
                                    <div class="spinner-border" style="height:50vh;" role="status"></div> \
                                </div> \
                            </div> \
                        </div> \
                    </div>');
                modal.appendTo(document.body);
                jQuery.post(
                    '?ajr=dataHsProperties', {
                        sourceFldList: sourceDataName
                    },
                    function (response) {
                        var responseHTML = jQuery(response);
                        // Add an 'None' option to result html returned
                        jQuery('<li data-fld=""><em>None</em></li>')
                            .insertBefore(responseHTML.find('li.data-fields').first());
                        // Set the current item
                        responseHTML
                            .find('li[data-fld="' + focusedLinkFld.val() + '"]').addClass('selected')
                        // Add to the modal body
                        modal.find('.modal-body')
                            .on(
                                'click',
                                'li[data-fld]',
                                function (e) {
                                    var dataFld = jQuery(this).attr('data-fld');
                                    focusedLinkFld.val(dataFld).trigger('change');
                                    modal.modal('hide');
                                }
                            )
                            .empty()
                            .append(
                                jQuery('<div class="properties-fld-list"></div>')
                                    .append(responseHTML)
                            );
                    }
                );
            }
            return modal;
        }


        /**
         * Reorder Item
         */
        function reorderItem(event, ui) {
            console.log(event, ui);
            reorderCreateDirtyButtons(ui.item.closest('table'));
            ui.item
                .attr('data-order-dirty', '1')
                .addClass('text-primary')
                .parent()
                    .children('[data-order-dirty]')
                        .last()
                            .prevAll()
                                .addClass('text-primary')
                                .end()
                            .nextAll()
                                .removeClass('text-primary');
        }


        /**
         * Reorder - display Save Display Order button
         */
        function reorderCreateDirtyButtons(table) {
            var button = reorderGetSaveButtonForTable(table);

            button.fadeIn('slow');
        }


        /**
         * Reorder - Get the save button for table
         * @param table
         * @return object Button
         */
        function reorderGetSaveButtonForTable(table) {
            var btnCntr = jQuery(table).find('thead div'),
                button = btnCntr.find('button');

            if (button.length === 0) {
                button = jQuery('<button class="btn btn-primary btn-sm float-right">Save Property <br>Display Order</button>');
                button
                    .on('click', reorderSaveGroup)
                    .fadeOut('fast')
                    .prependTo(btnCntr);
            }
            return button;
        }

        /**
         * Reorder - Save Order for table group
         */
        function reorderSaveGroup(event) {
            var table = jQuery(event.target).closest('table'),
                button = reorderGetSaveButtonForTable(table),
                lastToOrder = table.find('[data-order-dirty]').last(),
                reorder = [];

            lastToOrder
                .prevAll()
                    .add(lastToOrder)
                    .removeClass('text-primary')
                    .removeAttr('data-order-dirty')
                    .each(
                        function(_idx, element) {
                            var item = jQuery(element),
                                id = item.attr('data-id'),
                                reorder = [];

                            reorder.push(id);
                            console.log(reorder);
                            jQuery.post(
                                '?ajr=dataHsProperties',
                                { reorder: reorder.join(',') },
                                function(data) {
                                    if (data !== 'OK') alert(data);
                                }
                            )
                        }
                    )

            button.fadeOut('slow');
        }

    }

);